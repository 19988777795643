import React, { lazy, Suspense, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {
  BackgroundButtonwood,
  Ellipsis,
  ToastProvider,
  TokenListProvider,
  WalletConnectionProvider,
  Web3Provider,
  WrapperMapProvider,
} from 'common-client';
import { ConfigProvider, SUPPORTED_CHAINS } from 'config';
import { SubgraphProvider } from 'contexts/SubgraphContext';
import { TopBarWrapper } from 'wrappers/TopBarWrapper';
import { Box, BoxProps, styled } from '@mui/material';
import { withProfiler } from '@sentry/react';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ethereum: any;
  }
}

window.ethereum = window.ethereum || {};

const AppDiv = styled('div', { name: 'AppDiv' })(() => ({
  minHeight: '100vh',
  WebkitTapHighlightColor: 'transparent',
}));

const BodyBox = styled(Box, { name: 'BodyBox' })<BoxProps>(() => ({}));

const DynamicLoader = ({ component }: { component: string }) => {
  const LazyComponent = useMemo(() => lazy(() => import(`routes/${component}`)), [component]);
  return (
    <Suspense fallback={<Ellipsis />}>
      <LazyComponent />
    </Suspense>
  );
};

const queryClient = new QueryClient();

function App() {
  return (
    <IntlProvider locale={navigator.language}>
      <Router>
        <Web3Provider>
          <WalletConnectionProvider supportedChains={SUPPORTED_CHAINS}>
            <QueryClientProvider client={queryClient}>
              <TokenListProvider>
                <WrapperMapProvider>
                  <ConfigProvider>
                    <SubgraphProvider>
                      <ToastProvider>
                        <AppDiv>
                          <BackgroundButtonwood />
                          <TopBarWrapper />
                          <BodyBox
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            flex={1}
                          >
                            <Switch>
                              <Route path="/">
                                <DynamicLoader component="BondsRoute" />
                              </Route>
                            </Switch>
                          </BodyBox>
                        </AppDiv>
                      </ToastProvider>
                    </SubgraphProvider>
                  </ConfigProvider>
                </WrapperMapProvider>
              </TokenListProvider>
            </QueryClientProvider>
          </WalletConnectionProvider>
        </Web3Provider>
      </Router>
    </IntlProvider>
  );
}

export default withProfiler(App);
