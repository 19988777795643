import { Token } from '@uniswap/sdk-core';
import {
  addressEquals,
  Asset,
  Chain,
  createConfigs,
  LoanRouterConfig,
  NetworkConfig,
  SubgraphConfig,
  UsersnapButtonWrapper,
} from 'common-client';
import ethereumLogoUrl from 'common-client/dist/assets/images/ethereum-logo.png';
import React, { ReactElement } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import BalanceIcon from '@mui/icons-material/Balance';
// import InsertChartIcon from '@mui/icons-material/InsertChart';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PublicIcon from '@mui/icons-material/Public';
import TwitterIcon from '@mui/icons-material/Twitter';
import { ReactComponent as DiscordIcon } from 'assets/icons/DiscordIcon.svg';
import { createSvgIcon } from '@mui/material';
import tokenlist from 'buttonwood-token-list/buttonwood.tokenlist.json';

const SUBGRAPH_BASE_URI = 'https://api.thegraph.com/subgraphs/name';

export const SUPPORTED_CHAINS = [Chain.Mainnet, Chain.Goerli];

export const USERSNAP_GLOBAL_ID = '85158a56-8cfb-4f42-abe2-d5d25c70000c';
export const USERSNAP_PROJECT_ID = '452c40ec-0841-4628-9d74-30972c71639d';

// DO NOT EXPORT
function getToken(chainId: number, address: string): Token {
  const {
    name,
    symbol,
    decimals,
  } = tokenlist.tokens.find(
    (token) => token.chainId === chainId && addressEquals(token.address, address),
  )!;
  return new Token(chainId, address, decimals, symbol, name);
}

export interface NetworkConfigExtension extends NetworkConfig, LoanRouterConfig {
  acceptedBondMinters: string[];
}

export type ConfigExtension = Partial<Record<Chain, NetworkConfigExtension>>;

const config: ConfigExtension = {
  [Chain.Mainnet]: {
    network: {
      name: 'mainnet',
      chainId: 1,
      networkId: 1,
    },
    asset: {
      [Asset.USDT]: getToken(1, '0xdac17f958d2ee523a2206206994597c13d831ec7'),
      [Asset.AMPL]: getToken(1, '0xd46ba6d942050d489dbd938a2c909a5d5039a161'),
      [Asset.ETH]: getToken(1, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'),
      [Asset.WETH]: getToken(1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'),
      [Asset.WAMPL]: getToken(1, '0xEDB171C18cE90B633DB442f2A6F72874093b49Ef'),
      [Asset.WBTC]: getToken(1, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'),
    },
    chainInfo: {
      explorer: 'https://etherscan.io/',
      label: 'Ethereum',
      logoUrl: ethereumLogoUrl,
    },
    acceptedBondMinters: [
      '0xd7e86bd77784217324b4e94aedc68e5c8227ec2b',
      '0xc09e2b5532803fc065dc31418ddf9f34ae070dbb',
      '0xf7aba9b064a12330a00eafaa930e2fe8e76e65f0',
    ],
    loanRouterAddress: '0x5bDD0812f0F6CE66024ddAA92650F838e94d253E',
    wethLoanRouterAddress: '0x2119BD77031D5A6c69D7B15A3a73E15a91CBc721',
    wamplLoanRouterAddress: '0x06111C76430d31C640228609f334c207547fAaB8',
  },
  [Chain.Goerli]: {
    network: {
      name: 'goerli',
      chainId: 5,
      networkId: 5,
    },
    asset: {
      [Asset.USDT]: getToken(5, '0xaFF4481D10270F50f203E0763e2597776068CBc5'),
      [Asset.AMPL]: getToken(5, '0x08c5b39F000705ebeC8427C1d64D6262392944EE'),
      [Asset.ETH]: getToken(5, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'),
      [Asset.WETH]: getToken(5, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6'),
      [Asset.WAMPL]: getToken(5, '0x3b624861a14979537DE1B88F9565F41a7fc45FBf'),
      [Asset.WBTC]: getToken(5, '0xF19162950528A40a27d922f52413d26f71B25926'),
    },
    chainInfo: {
      explorer: 'https://goerli.etherscan.io/',
      label: 'Goerli',
      logoUrl: ethereumLogoUrl,
    },
    acceptedBondMinters: [
      '0x584feeeb3d87e6fe80f3360c48d5a7835d987e51',
      '0xa6b4ef3446749fc653272f4de387379f263b9d70',
      '0xa404c9676d58b08119d431493eeb963139a36bcf',
      '0x0aa379757db57625ac2919a57e37d427a334b7b8',
      '0xfd99d2d103b09f95c3dfc458f57178bf0cd587b1',
      '0x9d0c0d398fbb997a5814e5fa107673685f0e1e9f',
    ],
    loanRouterAddress: '0xF127dA0Bc9c6B612625B0A855a6f0f4fF8fBb945',
    wethLoanRouterAddress: '0xD60896A2B24008Ba1c5D6BD8215b2B59aba2E475',
    wamplLoanRouterAddress: '',
  },
};

export const SUBGRAPH_CONFIG: SubgraphConfig = {
  [Chain.Mainnet.valueOf()]: {
    tranche: `${SUBGRAPH_BASE_URI}/buttonwood-protocol/tranche`,
  },
  [Chain.Goerli.valueOf()]: {
    tranche: `${SUBGRAPH_BASE_URI}/buttonwood-protocol/tranche-goerli`,
  },
};

interface MenuLines {
  icon: ReactElement;
  name : string;
  url?: string;
  node?: ReactElement;
}

const GeneratedDiscordIcon = createSvgIcon(<DiscordIcon />, 'DiscordIcon');

export const menuConfig: Array<MenuLines> = [
  {
    icon: <TwitterIcon />,
    name: 'Twitter',
    url: 'https://twitter.com/ButtonDeFi',
  },
  {
    icon: <GeneratedDiscordIcon />,
    name: 'Discord',
    url: 'https://discord.gg/P8gFKUYtfG',
  },
  {
    icon: <PublicIcon />,
    name: 'Foundation',
    url: 'https://button.foundation/',
  },
  {
    icon: <ArticleIcon />,
    name: 'Docs',
    url: 'https://docs.prl.one',
  },
  // {
  //   icon: <InsertChartIcon />,
  //   name: 'Analytics',
  //   url: 'https://dune.xyz/prl/<placeholder-client>',
  // },
  {
    icon: <HelpCenterIcon />,
    name: 'About',
    url: 'https://tranche.button.finance',
  },
  {
    icon: <BalanceIcon />,
    name: 'Terms',
    url: 'https://tranche.button.finance/terms/',
  },
  {
    icon: <FeedbackIcon />,
    name: 'Feedback',
    node: <UsersnapButtonWrapper projectId={USERSNAP_PROJECT_ID} globalId={USERSNAP_GLOBAL_ID} />,
  },
];

export function getConfig(chain: Chain): NetworkConfigExtension | null {
  if (chain === Chain.Unsupported) {
    return null;
  }
  return config[chain] || null;
}

const { ConfigContext, ConfigProvider } = createConfigs(getConfig);
export { ConfigContext, ConfigProvider };
